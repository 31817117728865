import Component from '@averyano/core/src/classes/Component';
import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);
import Reveal from '../../animations/Reveal';

import each from 'lodash/each';

export default class AwardSection extends Component {
	constructor() {
		super({
			element: '.award',
			elements: {
				titles: '.awards__title',
				left: '.awards__left',
				right: '.awards__right',
				items: '.awards__item',
			},
		});
	}

	create() {
		super.createComponent();

		this.createTimeline();
		this.animateTimeline();
		this.addEventListeners();
		// this.addRevealAnimation();
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.elements.left,
				start: 'top bottom',
				end: 'top bottom',
				toggleActions: 'restart none none reverse',
				// markers: true,
			},
		});

		ScrollTrigger.create({
			trigger: this.elements.left,
			// start: 'center center',
			// end: 'center center',
			onEnter: () => this.$emit('navChangeIndex', 2),
			onEnterBack: () => this.$emit('navChangeIndex', 2),
		});
	}

	animateTimeline() {
		this.timeline.fromTo(
			this.elements.titles,
			{ opacity: 0 },
			{ opacity: 1, duration: 0.7, stagger: 0.6, ease: 'power4.out' },
			0
		);

		this.timeline.fromTo(
			this.elements.items,
			{
				opacity: 0,
				// y: 100,
			},
			{
				opacity: 1,
				// y: 0,
				stagger: 0.1,
				duration: 0.3,
				ease: 'power4.out',
			},
			0
		);
	}

	addEventListeners() {
		const linksLeft = this.elements.left.querySelectorAll('a');
		each(linksLeft, (link) => {
			const linkTitle = link.querySelector('.awards__item__title');
			link.addEventListener('click', () => {
				window.globalAnalyticsInstance.trackEvent('Education', {
					action: 'Click',
					label: linkTitle,
				});
			});
		});

		const linksRight = this.elements.right.querySelectorAll('a');
		each(linksRight, (link) => {
			const linkTitle = link.querySelector('.awards__item__title');
			link.addEventListener('click', () => {
				window.globalAnalyticsInstance.trackEvent('Awards', {
					action: 'Click',
					label: linkTitle,
				});
			});
		});
	}

	removeEventListeners() {
		const linksLeft = this.elements.left.querySelectorAll('a');
		each(linksLeft, (link) => {
			const linkTitle = link.querySelector('.awards__item__title');
			link.removeEventListener('click', () => {
				window.globalAnalyticsInstance.trackEvent('Education', {
					action: 'Click',
					label: linkTitle,
				});
			});
		});

		const linksRight = this.elements.right.querySelectorAll('a');
		each(linksRight, (link) => {
			const linkTitle = link.querySelector('.awards__item__title');
			link.removeEventListener('click', () => {
				window.globalAnalyticsInstance.trackEvent('Awards', {
					action: 'Click',
					label: linkTitle,
				});
			});
		});
	}

	destroy() {
		super.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}
	}
}
