import Component from '@averyano/core/src/classes/Component';
import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);

export default class WelcomeSection extends Component {
	constructor() {
		super({
			element: '.welcome',
			elements: {
				title: '.welcome__title',
				video: '.welcome__video',
				videoWrapper: '.welcome__video__wrapper',
				textWrapper: '.welcome__text__wrapper',
				textElements: '.welcome__text__element',
				sectionWrapper: '.section__wrapper',
				sectionTitle: '.section__title',
			},
		});
	}

	create() {
		super.createComponent();

		this.createTimeline();
		this.animateTimeline();
		this.addEventListeners();
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				scrub: 1,
				start: 'bottom bottom',
				end: 'bottom top',
			},
		});

		this.timelineInstant = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				onEnter: () => this.playWelcomeVideo(),
				onLeave: () => this.pauseWelcomeVideo(),
				onEnterBack: () => this.playWelcomeVideo(),
				onLeaveBack: () => this.pauseWelcomeVideo(),
				start: 'top bottom',
				end: 'bottom top',
				// markers: true,
			},
		});

		ScrollTrigger.create({
			trigger: this.element,
			end: 'bottom+=25% top',
			onEnter: () => this.$emit('navChangeIndex', 1),
			onEnterBack: () => this.$emit('navChangeIndex', 1),
		});
	}

	animateTimeline() {
		this.timeline.fromTo(
			this.elements.videoWrapper,
			{
				rotateX: 0,
			},
			{
				rotateX: -5,
				duration: 1.5,
				ease: 'out.expo',
			}
		);

		// instance timeline playWelcomeVideo on trigger
	}

	playWelcomeVideo() {
		window.globalAnalyticsInstance.trackEvent('Hello and Welcome', {
			action: 'Video Interaction',
			label: 'Play',
		});

		if (this.elements.video.paused) {
			const playPromise = this.elements.video.play();
			this.elements.videoWrapper.classList.remove('paused');

			if (playPromise !== undefined) {
				playPromise.catch((error) => {
					console.error('Video play failed:', error);
				});
			}
		}
	}
	pauseWelcomeVideo() {
		window.globalAnalyticsInstance.trackEvent('Hello and Welcome', {
			action: 'Video Interaction',
			label: 'Pause',
		});

		if (!this.elements.video.paused) {
			this.elements.video.pause();
			this.elements.videoWrapper.classList.add('paused');
		}
	}

	destroy() {
		super.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}
		if (this.timelineInstant) {
			this.timelineInstant.kill();
			this.timelineInstant = null;
		}
	}

	addEventListeners() {
		this.elements.videoWrapper.addEventListener('click', () => {
			this.elements.video.paused
				? this.playWelcomeVideo()
				: this.pauseWelcomeVideo();
		});
	}

	removeEventListeners() {
		this.elements.videoWrapper.removeEventListener('click', () => {
			this.elements.video.paused
				? this.playWelcomeVideo()
				: this.pauseWelcomeVideo();
		});
	}

	onResize() {
		console.log('resize');
		const height = document
			.querySelector('.nav__wrapper')
			.getBoundingClientRect().height;
		// this.elements.sectionWrapper.style.paddingTop = height + 'px';
		this.elements.sectionTitle.style.height = height + 'px';
	}
}
