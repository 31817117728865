import map from 'lodash/map';
import GSAP from 'gsap';
import Component from '@averyano/core/src/classes/Component';
import each from 'lodash/each';

export default class ExpertiseList extends Component {
	constructor() {
		super({
			element: '.expertise__bottom',
			elements: {
				listWrapper: '.expertise__bottom__list',
				listItems: '.expertise__bottom__list__item',
				bottomWrapper: '.expertise__bottom__content',
				bottomDescription: '.expertise__bottom__content__text',
				bottomLine: '.expertise__bottom__list__line',
			},
		});

		this.texts = EXPERTISE[window.lang]; // webpack.config.base.js
		console.log(EXPERTISE);
		console.log(this.texts);
		this.currentType = 'light';
		this.currentIndex = 0;

		this.createDescriptionTimeline();
	}

	createDescriptionTimeline() {
		this.descriptionTimeline = GSAP.timeline({ paused: true });

		this.descriptionTimeline.fromTo(
			this.elements.bottomWrapper,
			{
				autoAlpha: 0,
				y: 50,
			},
			{
				autoAlpha: 1,
				y: 0,
				duration: 2,
				ease: 'power4.out',
			}
		);
	}
	hideList(direction) {
		return new Promise((resolve) => {
			GSAP.killTweensOf(this.elements.listItems);
			// let numberOfDeactivated = 0;
			let itemsToAnimate = [];
			each(this.elements.listItems, (item) => {
				if (!item.classList.contains('deactivated'))
					direction === 1
						? itemsToAnimate.push(item)
						: itemsToAnimate.unshift(item);
			});

			// Hide Line
			if (direction === -1) {
				GSAP.to(this.elements.bottomLine, {
					scaleX: 0,
					transformOrigin: 'left',
					duration: 1.25,
					ease: 'power2.out',
				});
			} else {
				GSAP.to(this.elements.bottomLine, {
					scaleX: 0,
					transformOrigin: 'right',
					duration: 1.25,
					ease: 'power2.out',
				});
			}
			// Hide List Elements
			GSAP.to(this.elements.bottomWrapper, {
				autoAlpha: 0,
				duration: 1.25,
				// onComplete: () => resolve(),
			});
			GSAP.to(itemsToAnimate, {
				autoAlpha: 0,
				duration: 0.5,
				stagger: 0.12,
				ease: 'power2.out',
				onComplete: () => resolve(),
			});
			// GSAP.to(this.elements.listItems, {
			// 	autoAlpha: 0,
			// 	duration: 1,
			// 	// onComplete: () => resolve(),
			// });
		});
	}

	showList(direction) {
		return new Promise((resolve) => {
			GSAP.killTweensOf(this.elements.listItems);

			let itemsToAnimate = [];
			each(this.elements.listItems, (item) => {
				if (!item.classList.contains('deactivated'))
					direction === 1
						? itemsToAnimate.push(item)
						: itemsToAnimate.unshift(item);
			});

			// Show Line
			if (direction === 1) {
				GSAP.to(this.elements.bottomLine, {
					scaleX: 1,
					transformOrigin: 'left',
					duration: 1.25,
					ease: 'power2.out',
				});
			} else {
				GSAP.to(this.elements.bottomLine, {
					scaleX: 1,
					transformOrigin: 'right',
					duration: 1.25,
					ease: 'power2.out',
				});
			}

			// Show List
			GSAP.to(itemsToAnimate, {
				autoAlpha: 1,
				duration: 0.5,
				stagger: 0.2,
			});
			GSAP.to(this.elements.listWrapper, {
				autoAlpha: 1,
				duration: 1,
				onComplete: () => resolve(),
			});
		});
	}

	replaceListContent() {
		return new Promise((resolve) => {
			map(this.elements.listItems, (item) => {
				item.classList.remove('deactivated');
				item.textContent = '';
			});

			map(this.texts[this.currentType], (item, i) => {
				this.elements.listItems[i].textContent = item.title;
			});

			map(this.elements.listItems, (item) => {
				if (item.textContent === '') item.classList.add('deactivated');
			});

			resolve();
		});
	}

	replaceDescriptionContent(i, force) {
		if (this.elements.listItems[i].classList.contains('active') && !force)
			return;

		map(this.elements.listItems, (item) => {
			item.classList.remove('active');
		});

		this.elements.listItems[i].classList.add('active');

		this.elements.bottomDescription.textContent =
			this.texts[this.currentType][i].text;

		this.descriptionTimeline.restart();
	}

	addEventListeners() {
		this.elements.listWrapper.addEventListener('click', (e) => {
			const num = e.target.dataset.num;
			if (num) {
				const designTxt = window.lang === 'en' ? 'Design' : 'Дизайн';
				window.globalAnalyticsInstance.trackEvent(
					`${designTxt} ${
						this.currentType.charAt(0).toUpperCase() + this.currentType.slice(1)
					}`,
					{
						action: 'Click',
						label: e.target.textContent,
					}
				);
				this.replaceDescriptionContent(parseInt(num));
			}
		});
	}

	removeEventListeners() {
		this.elements.listWrapper.removeEventListener('click', (e) => {
			const num = e.target.dataset.num;
			if (num) {
				const designTxt = window.lang === 'en' ? 'Design' : 'Дизайн';
				window.globalAnalyticsInstance.trackEvent(
					`${designTxt} ${
						this.currentType.charAt(0).toUpperCase() + this.currentType.slice(1)
					}`,
					{
						action: 'Click',
						label: e.target.textContent,
					}
				);
				this.replaceDescriptionContent(parseInt(num));
			}
		});
	}

	destroy() {
		if (this.descriptionTimeline) {
			this.descriptionTimeline.kill();
			this.descriptionTimeline = null;
		}
	}
}
